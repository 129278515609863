@import 'Styles/Medborgarskolan/includes';

/**
*
* SlickSlider
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SlickSlider.Base.module.scss';

.SlickSlider {
    $root: &;

    &__Slide {
        padding-left: 16px;

        @include media(sm) {
            padding-left: 24px;
        }
    }

    &__Title {
        margin-bottom: 26px;
        color: $blue;
        font-size: 3.4rem;
        font-weight: $bold;
        line-height: 42px;

        @include media(sm) {
            margin-bottom: 40px;
            font-size: 4.8rem;
            line-height: 59px;
        }
    }

    &__Control {
        top: -70px;
        width: 44px;
        height: 44px;
        border-color: $blue;

        svg {
            fill: $blue;
        }

        &--Disabled {
            opacity: 1;
            border-color: $grey-60;

            svg {
                fill: $grey-60;
            }
        }

        @include media(sm) {
            top: -86px;
        }

        &--Next {
            right: 0;

            #{$root}--MobileInset & {
                right: 12px; /* Add for spacing being removed slick-slider */

                @include media(sm) {
                    right: 0;
                }
            }

            svg {
                top: 14px !important;
                left: 17px !important;
                transform: rotate(180deg);
            }
        }

        &--Prev {
            right: 54px;

            #{$root}--MobileInset & {
                right: 12px + 54px; /* Add for spacing being removed slick-slider */

                @include media(sm) {
                    right: 54px;
                }
            }

            svg {
                top: 14px !important;
                left: 16px !important;
            }
        }

        &:hover:not(#{$root}__Control--Disabled) {
            background: $blue;

            @media (hover: none) {
                background: transparent;
            }

            svg {
                fill: white;

                @media (hover: none) {
                    fill: $blue;
                }
            }
        }
    }

    &__Button {
        @include media(sm) {
            margin-top: 8px;
        }
    }
}
