@import 'Styles/Pahlmans/includes';

/**
*
* SlickSlider
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SlickSlider.Base.module.scss';

.SlickSlider {
    $root: &;

    &__Title {
        margin-bottom: 25px;
        font-family: $font-base;
        font-size: 1.8rem;
        font-weight: $bold;
        text-transform: uppercase;
    }

    &__Control {
        position: absolute;
        top: -65px;
        width: 48px;
        height: 48px;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid palette(black, 15);
        background: white;
        transition: all 0.2s ease-in;
        display: flex;
        align-items: center;
        justify-content: center;

        &--Disabled {
            opacity: 1;

            svg {
                opacity: .2;
            }
        }

        &--Next {
            svg {
                left: 20px !important;
            }
        }

        &--Prev {
            right: 47px;
            border-radius: 4px 0px 0px 4px;

            #{$root}--MobileInset & {
                right: 12px + 47px;  /* Add for spacing being removed slick-slider */
                
                @include media(sm) {
                    right: 47px;
                }
            }
        }

        svg {
            top: 17px;
            left: 18px;
        }

        &:hover:not(#{$root}__Control--Disabled) {
            background: transparent;

            svg {
                fill: black;

                @media(hover: none) {
                    fill: black;
                }
            }
        }
    }
}
