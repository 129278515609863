@import 'Styles/Kulturama/includes';

/**
*
* SliderCardArticle
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SliderCardArticle.Base.module.scss';

.SliderCardArticle {
    background: palette(black, 5);

    &__Container {
        padding-top: 40px;
        padding-bottom: 20px;

        @include media(sm) {
            padding-top: 80px;
            padding-bottom: 60px;
        }
    }
}
