@import 'Styles/Medborgarskolan/includes';

/**
*
* SliderCardArticle
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SliderCardArticle.Base.module.scss';

.SliderCardArticle {
    $root: &;

    position: relative;
    background-color: $blue-20;

    &__Container {
        padding-top: 48px;
        padding-bottom: 28px;

        @include media(md) {
            padding-top: 72px;
            padding-bottom: 68px;
        }

        #{$root}--NoTopSpacing & {
            padding-top: 0 !important;
        }
    }
}
