@import 'Styles/Kulturama/includes';

/**
*
* SlickSlider
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SlickSlider.Base.module.scss';

.SlickSlider {
    $root: &;

    &__Title {
        // For SliderCardSubjectList
        #{$root}--CardSubjectCampaign & {
            @include media(sm) {
                font-size: 2.8rem;
            }
        }

        :global(.PageCampaign) #{$root}--CardNews & {
            @include media(md) {
                margin-bottom: 32px;
                font-size: 5.2rem;
            }
        }
    }

    &__Control {
        &:hover:not(#{$root}__Control--Disabled) {
            border-color: palette(pink);
            background: transparent;

            @media(hover: none) {
                border-color: black;
                background: transparent;
            }

            svg {
                fill: palette(pink);

                @media(hover: none) {
                    fill: black;
                }
            }
        }
    }
}
